<template>
  <div class="flex justify-between items-center p-2 shadow">
    <div class="text-2xl pl-5">{{ title }}</div>
    <Account />
  </div>
</template>

<script>
import Account from "@/components/Common/Navbar/Account";

export default {
  name: "NavbarStandard",
  components: { Account },
  props: {
    title: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
  mounted() {},
};
</script>

<style scoped></style>
