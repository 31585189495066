<template>
  <div
    :class="{
      'w-48': open,
    }"
    class="w-20 h-screen relative ml-2"
  >
    <div
      :class="{
        'w-48': open,
      }"
      class="w-20 duration-300 flex flex-col justify-between h-full fixed bg-nSidebarBg pt-6"
    >
      <div class="flex flex-col">
        <img
          :src="require('@/assets/images/sidebar/arrow-left-circle.svg')"
          :class="{
            'transform rotate-180': !open,
          }"
          class="arrow-left-circle absolute -right-3 w-7 rounded-full cursor-pointer"
          @click="open = !open"
        />
        <Logo :open="open" class="place-self-center" />
        <ul class="pt-10">
          <li
            v-for="(item, index) in menus"
            :key="index"
            :class="{
              'mt-9': item.gap,
              'bg-white border-r-4 border-nSidebarRed text-nSidebarRed':
                $route.name === item.title,
            }"
            class="text-white hover:text-nSidebarRed text-sm cursor-pointer p-2 hover:bg-white mt-3 group"
          >
            <router-link
              :to="{
                name: item.title,
              }"
              class="flex items-center gap-x-4 pl-5"
            >
              <div class="w-5 h-5">
                <div class="block group-hover:hidden">
                  <img
                    :src="
                      require('@/assets/images/sidebar/icons/' +
                        item.src +
                        '-selected.svg')
                    "
                    class="w-5 h-5"
                    v-if="$route.name === item.title"
                  />
                  <img
                    :src="
                      require('@/assets/images/sidebar/icons/' +
                        item.src +
                        '.svg')
                    "
                    class="w-5 h-5"
                    v-else
                  />
                </div>
                <div class="hidden group-hover:block">
                  <img
                    :src="
                      require('@/assets/images/sidebar/icons/' +
                        item.src +
                        '-selected.svg')
                    "
                    class="w-5 h-5"
                  />
                </div>
              </div>
              <span
                :class="{
                  'hidden origin-left duration-200': !open,
                }"
                class="font-semibold"
                >{{ item.title }}</span
              >
            </router-link>
          </li>
        </ul>
      </div>
      <Footer :open="open" class="mb-4 mx-3" />
    </div>
  </div>
</template>

<script>
import Logo from "@/components/Common/Sidebar/Logo";
import Footer from "@/components/Common/Sidebar/Footer";

export default {
  name: "SidebarStandard",
  components: { Logo, Footer },
  data() {
    return {
      open: true,
      menus: [
        { title: "Dashboard", src: "dashboard", gap: false },
        { title: "Reports", src: "reports", gap: false },
        { title: "Users", src: "users", gap: false },
        { title: "Profile", src: "profile", gap: false },
        { title: "Settings", src: "settings", gap: false },
        { title: "Tickets", src: "tickets", gap: false },
      ],
    };
  },
  methods: {},
  computed: {},
  mounted() {},
};
</script>

<style scoped>
.arrow-left-circle {
  top: 4.6rem;
}
</style>
